import { PropsWithChildren, SVGProps, useId } from 'react';

interface SVGIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const SVGIcon = ({
  children,
  viewBox = '0 0 24 24',
  size = 24,
  ...props
}: PropsWithChildren<SVGIconProps>) => {
  return (
    <svg width={size} height={size} viewBox={viewBox} {...props}>
      {children}
    </svg>
  );
};

export const ArrowLeftIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
        fill="currentColor"
      />
    </SVGIcon>
  );
};

export const MenuIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 20 20">
      <path
        d="M2.5 10H17.5M2.5 5H17.5M2.5 15H17.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </SVGIcon>
  );
};

export const HelpIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        d="M11 18H13V16H11V18ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 6C9.79 6 8 7.79 8 10H10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 12 11 11.75 11 15H13C13 12.75 16 12.5 16 10C16 7.79 14.21 6 12 6Z"
        fill="currentColor"
      />
    </SVGIcon>
  );
};

export const XCrossIcon = (props: SVGIconProps) => {
  const id = 'clip-' + useId();
  return (
    <SVGIcon {...props}>
      <g clipPath={`url(#${id})`}>
        <path
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </SVGIcon>
  );
};

export const RefreshIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        d="M17.645 6.35C16.195 4.9 14.205 4 11.995 4C7.575 4 4.005 7.58 4.005 12C4.005 16.42 7.575 20 11.995 20C15.725 20 18.835 17.45 19.725 14H17.645C16.825 16.33 14.605 18 11.995 18C8.685 18 5.995 15.31 5.995 12C5.995 8.69 8.685 6 11.995 6C13.655 6 15.135 6.69 16.215 7.78L12.995 11H19.995V4L17.645 6.35Z"
        fill="currentColor"
      />
    </SVGIcon>
  );
};

export const PurchaseIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 60 61" fill={'none'}>
      <rect y="0.5" width="60" height="60" rx="30" fill="#FF5A1E" />
      <path
        d="M42.5 35.5L42.5 39.5L17.5 39.5L17.5 21.5L42.5 21.5L42.5 25.5"
        stroke="white"
        strokeWidth="2"
      />
      <path d="M37.5 30.5L48 30.5" stroke="white" strokeWidth="2" />
      <path d="M45.5 27.5L48.5 30.5L45.5 33.5" stroke="white" strokeWidth="2" />
      <circle
        cx="29.5"
        cy="30.5"
        r="4"
        transform="rotate(-180 29.5 30.5)"
        stroke="white"
        strokeWidth="2"
      />
    </SVGIcon>
  );
};

export const RefundIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 60 61" fill={'none'}>
      <rect y="0.5" width="60" height="60" rx="30" fill="#57433C" />
      <path d="M18 25.5V21.5H43V39.5H18V35.5" stroke="white" strokeWidth="2" />
      <path d="M10 30.5H21.5" stroke="white" strokeWidth="2" />
      <path d="M19 27.5L22 30.5L19 33.5" stroke="white" strokeWidth="2" />
      <circle cx="31" cy="30.5" r="4" stroke="white" strokeWidth="2" />
    </SVGIcon>
  );
};

export const FAQIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 38 38">
      <rect width="38" height="38" rx="19" fill="#FF5A1E" />
      <path d="M28.5 15H15.5V24.88H25L28.5 28V15Z" fill="white" />
      <path d="M12.5 13H25.5V22.5H14L12.5 25.5V13Z" fill="#FF5A1E" />
      <path d="M10.5 11H23.5V20.5H14L10.5 23.5V11Z" fill="white" />
    </SVGIcon>
  );
};

export const EyeIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <g opacity="0.6">
        <path
          d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SVGIcon>
  );
};

export const DoneAllIcon = (props: SVGIconProps) => {
  const id = 'clip-' + useId();
  return (
    <SVGIcon {...props}>
      <g clipPath={`url(#${id})`}>
        <path
          d="M18.2761 6.70504L16.8661 5.29504L10.5261 11.635L11.9361 13.045L18.2761 6.70504ZM22.5161 5.29504L11.9361 15.875L7.75611 11.705L6.34611 13.115L11.9361 18.705L23.9361 6.70504L22.5161 5.29504ZM0.686111 13.115L6.27611 18.705L7.68611 17.295L2.10611 11.705L0.686111 13.115Z"
          fill="#83BF29"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" transform="translate(0.311111)" />
        </clipPath>
      </defs>
    </SVGIcon>
  );
};

export const ChevronRightIcon = (props: SVGIconProps) => {
  const id = 'clip-' + useId();
  return (
    <SVGIcon {...props}>
      <g clipPath={`url(#${id})`}>
        <path
          d="M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SVGIcon>
  );
};

export const PlacemarkIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox={'0 0 21 24'} width="21" height="24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 21C10 21 17 13.866 17 8C17 4.13401 13.866 1 10 1C6.13401 1 3 4.13401 3 8C3 13.866 10 21 10 21ZM10 11C11.6569 11 13 9.65685 13 8C13 6.34315 11.6569 5 10 5C8.34315 5 7 6.34315 7 8C7 9.65685 8.34315 11 10 11Z"
        fill="#FF5A1E"
      />
    </SVGIcon>
  );
};

export const SpinnerIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon
      {...props}
      viewBox="0 0 67 67"
      fill="none"
      style={{
        display: 'block',
      }}
    >
      <circle cx="33.5" cy="33.5" r="33.5" fill="white" />
      <path
        d="M17.9636 44.6248C12.0963 36.4513 13.5812 25.0206 21.517 18.6385C29.7301 12.0335 41.7425 13.337 48.3476 21.5501C54.9527 29.7631 53.6491 41.7756 45.4361 48.3807C44.0736 49.4764 42.6066 50.3544 41.077 51.0194"
        stroke="#FF5A1E"
        strokeWidth="3"
      />
      <path
        d="M40.7652 29.4063C42.9252 33.2382 41.7347 38.1349 37.9815 40.53C34.0973 43.0087 28.939 41.8693 26.4603 37.985C23.9816 34.1007 25.121 28.9425 29.0053 26.4638C29.6496 26.0526 30.329 25.741 31.0255 25.5249"
        stroke="#FF5A1E"
        strokeWidth="3"
      />
    </SVGIcon>
  );
};

export const SendFormIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 40 40" fill="none">
      <path d="M9.75 20.75V27.25L29.25 19.25V18.75L9.75 20.75Z" fill="white" />
      <path d="M9.75 17.25V10.75L29.25 18.75V19.25L9.75 17.25Z" fill="white" />
    </SVGIcon>
  );
};

export const OkCircleIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 25 24" fill="none">
      <path
        d="M8.19531 12L11.1953 15L17.1953 9M22.6953 12C22.6953 17.5228 18.2182 22 12.6953 22C7.17246 22 2.69531 17.5228 2.69531 12C2.69531 6.47715 7.17246 2 12.6953 2C18.2182 2 22.6953 6.47715 22.6953 12Z"
        stroke="#4DC31E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVGIcon>
  );
};

export const MakePhotoIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.69531 5H7.69531L9.69531 3H15.6953L17.6953 5H20.6953C21.2257 5 21.7345 5.21071 22.1095 5.58579C22.4846 5.96086 22.6953 6.46957 22.6953 7V19C22.6953 19.5304 22.4846 20.0391 22.1095 20.4142C21.7345 20.7893 21.2257 21 20.6953 21H4.69531C4.16488 21 3.65617 20.7893 3.2811 20.4142C2.90603 20.0391 2.69531 19.5304 2.69531 19V7C2.69531 6.46957 2.90603 5.96086 3.2811 5.58579C3.65617 5.21071 4.16488 5 4.69531 5ZM12.6953 8C11.3692 8 10.0975 8.52678 9.15978 9.46447C8.2221 10.4021 7.69531 11.6739 7.69531 13C7.69531 14.3261 8.2221 15.5979 9.15978 16.5355C10.0975 17.4732 11.3692 18 12.6953 18C14.0214 18 15.2932 17.4732 16.2308 16.5355C17.1685 15.5979 17.6953 14.3261 17.6953 13C17.6953 11.6739 17.1685 10.4021 16.2308 9.46447C15.2932 8.52678 14.0214 8 12.6953 8ZM12.6953 10C13.491 10 14.254 10.3161 14.8166 10.8787C15.3792 11.4413 15.6953 12.2044 15.6953 13C15.6953 13.7956 15.3792 14.5587 14.8166 15.1213C14.254 15.6839 13.491 16 12.6953 16C11.8997 16 11.1366 15.6839 10.574 15.1213C10.0114 14.5587 9.69531 13.7956 9.69531 13C9.69531 12.2044 10.0114 11.4413 10.574 10.8787C11.1366 10.3161 11.8997 10 12.6953 10Z"
        fill="currentColor"
      />
    </SVGIcon>
  );
};

export const PurchaseSuccessIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" fill="none">
      <circle cx="38" cy="38" r="38" fill="#E9F3DB" />
      <path
        d="M55.9727 39C55.4538 48.476 47.6056 56 38 56C28.0589 56 20 47.9411 20 38C20 28.0589 28.0589 20 38 20C39.6491 20 41.2465 20.2218 42.7638 20.6371"
        stroke="#8EC34F"
        strokeWidth="3"
      />
      <path d="M28.5 35.5L35.5 42.5L53.5 25" stroke="#8EC34F" strokeWidth="3" />
    </SVGIcon>
  );
};

export const RefundSuccessIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" fill="none">
      <circle cx="38" cy="38" r="38" fill="#FCEEDB" />
      <path
        d="M38.6161 54.9998C28.93 54.9998 21.0779 47.1476 21.0779 37.4615C21.0779 32.7548 22.932 28.4811 25.9496 25.331"
        stroke="#FF5A1E"
        strokeWidth="3"
      />
      <path d="M20.1035 24.795H26.9239V31.6154" stroke="#FF5A1E" strokeWidth="3" />
      <path
        d="M50.8045 48.2513C56.9962 40.8027 55.9773 29.7449 48.5287 23.5531C45.5954 21.1148 42.1024 19.7947 38.5674 19.5451"
        stroke="#FF5A1E"
        strokeWidth="3"
      />
      <path d="M56.1543 49.1537L49.3339 49.1537L49.3339 42.3333" stroke="#FF5A1E" strokeWidth="3" />
    </SVGIcon>
  );
};

export const DeclineIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" fill="none">
      <circle cx="38" cy="38" r="38" fill="#FEEAE5" />
      <path
        d="M36.9863 55.9864C27.5103 55.4675 19.9863 47.6192 19.9863 38.0137C19.9863 28.0725 28.0452 20.0137 37.9863 20.0137C47.9275 20.0137 55.9863 28.0725 55.9863 38.0137C55.9863 39.6628 55.7646 41.2601 55.3492 42.7775"
        stroke="#FF0000"
        strokeWidth="3"
      />
      <path d="M51 25L25 51" stroke="#FF0000" strokeWidth="3" />
      <path
        d="M55.9727 39C55.4538 48.476 47.6056 56 38 56C28.0589 56 20 47.9411 20 38C20 28.0589 28.0589 20 38 20C39.6491 20 41.2465 20.2218 42.7638 20.6371"
        stroke="#FF0000"
        strokeWidth="3"
      />
    </SVGIcon>
  );
};

export const UpdateIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M16.1745 5.82082C14.8453 4.49166 13.0211 3.66666 10.9953 3.66666C6.94364 3.66666 3.67114 6.94832 3.67114 11C3.67114 15.0517 6.94364 18.3333 10.9953 18.3333C14.4145 18.3333 17.2653 15.9958 18.0811 12.8333H16.1745C15.4228 14.9692 13.3878 16.5 10.9953 16.5C7.96114 16.5 5.49531 14.0342 5.49531 11C5.49531 7.96582 7.96114 5.49999 10.9953 5.49999C12.517 5.49999 13.8736 6.13249 14.8636 7.13166L11.912 10.0833H18.3286V3.66666L16.1745 5.82082Z"
        fill="currentColor"
      />
    </SVGIcon>
  );
};

export const AvailableAmountIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#EBF3DB" />
      <path
        d="M29.965 18.705L28.555 17.295L22.215 23.635L23.625 25.045L29.965 18.705ZM34.205 17.295L23.625 27.875L19.445 23.705L18.035 25.115L23.625 30.705L35.625 18.705L34.205 17.295ZM12.375 25.115L17.965 30.705L19.375 29.295L13.795 23.705L12.375 25.115Z"
        fill="#83BF29"
      />
    </SVGIcon>
  );
};

export const ShareIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.838 13.0299C20.0553 12.8437 20.164 12.7505 20.2038 12.6397C20.2387 12.5424 20.2387 12.436 20.2038 12.3387C20.164 12.2279 20.0553 12.1348 19.838 11.9485L12.2973 5.48504C11.9232 5.16439 11.7362 5.00407 11.5778 5.00014C11.4402 4.99672 11.3087 5.05719 11.2217 5.1639C11.1217 5.2867 11.1217 5.53305 11.1217 6.02575V9.84942C9.22135 10.1819 7.48213 11.1448 6.18965 12.5906C4.78056 14.1668 4.0011 16.2067 4 18.3209V18.8657C4.93413 17.7404 6.10045 16.8303 7.41907 16.1978C8.58163 15.64 9.83835 15.3097 11.1217 15.2226V18.9527C11.1217 19.4454 11.1217 19.6917 11.2217 19.8145C11.3087 19.9212 11.4402 19.9817 11.5778 19.9783C11.7362 19.9744 11.9232 19.814 12.2973 19.4934L19.838 13.0299Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVGIcon>
  );
};

export const LogoutIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 16.5V14.5C4 12.8431 5.34315 11.5 7 11.5H18C19.6569 11.5 21 12.8431 21 14.5V24.5C21 26.1569 19.6569 27.5 18 27.5H7C5.34315 27.5 4 26.1569 4 24.5V22.5"
        stroke="#6D6F73"
        strokeWidth="2"
      />
      <path
        d="M4 19.5L15 19.5"
        stroke="#6D6F73"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.5L16 19.5L12 23.5"
        stroke="#6D6F73"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVGIcon>
  );
};

export const PurchaseSidebarIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="11" width="21" height="16" rx="3" fill="#6D6F73" />
      <path
        d="M10 19H11.5H13"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M12 14L18 19L12 24" fill="white" />
    </SVGIcon>
  );
};

export const RefundSidebarIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="10.5" width="21" height="16" rx="3" fill="#6D6F73" />
      <path
        d="M15 18.5L13.5 18.5L12 18.5"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M13 23.5L7 18.5L13 13.5" fill="white" />
    </SVGIcon>
  );
};

export const HomeSidebarIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 10.5H3.5V20.5H11.5V10.5ZM21.5 18.5H13.5V28.5H21.5V18.5ZM3.5 22.5H11.5V28.5H3.5V22.5ZM21.5 10.5H13.5V16.5H21.5V10.5Z"
        fill="currentColor"
      />
    </SVGIcon>
  );
};

export const PlayIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 3.98963C1.5 3.01847 1.5 2.53289 1.70249 2.26522C1.87889 2.03203 2.14852 1.88773 2.4404 1.8703C2.77544 1.8503 3.17946 2.11965 3.98752 2.65835L14.5031 9.66873C15.1708 10.1139 15.5046 10.3364 15.6209 10.6169C15.7227 10.8622 15.7227 11.1378 15.6209 11.3831C15.5046 11.6636 15.1708 11.8862 14.5031 12.3313L3.98752 19.3417C3.17946 19.8804 2.77544 20.1497 2.4404 20.1297C2.14852 20.1123 1.87889 19.968 1.70249 19.7348C1.5 19.4671 1.5 18.9815 1.5 18.0104V3.98963Z"
        stroke="#6D6F73"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SVGIcon>
  );
};

export const UserIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 13.9199H9.50001C8.10444 13.9199 7.40666 13.9199 6.83886 14.0922C5.56046 14.48 4.56004 15.4804 4.17224 16.7588C4 17.3266 4 18.0244 4 19.4199H20C20 18.0244 20 17.3266 19.8278 16.7588C19.44 15.4804 18.4395 14.48 17.1611 14.0922C16.5933 13.9199 15.8956 13.9199 14.5 13.9199Z"
        fill="currentColor"
      />
      <path
        d="M12 12C14.4853 12 16.5 9.98528 16.5 7.5C16.5 5.01472 14.4853 3 12 3C9.51472 3 7.5 5.01472 7.5 7.5C7.5 9.98528 9.51472 12 12 12Z"
        fill="currentColor"
      />
    </SVGIcon>
  );
};

export const AdditionalServiceCheckboxHelpIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99967 13.6663C10.6816 13.6663 13.6663 10.6816 13.6663 6.99967C13.6663 3.31778 10.6816 0.333008 6.99967 0.333008C3.31778 0.333008 0.333008 3.31778 0.333008 6.99967C0.333008 10.6816 3.31778 13.6663 6.99967 13.6663ZM6.99967 10.9997C6.63148 10.9997 6.33301 10.7012 6.33301 10.333C6.33301 9.96482 6.63148 9.66634 6.99967 9.66634C7.36786 9.66634 7.66634 9.96482 7.66634 10.333C7.66634 10.7012 7.36786 10.9997 6.99967 10.9997ZM4.41521 5.00641C4.70871 3.85252 5.75414 2.99967 6.99967 2.99967C8.47301 2.99967 9.66634 4.19301 9.66634 5.66634C9.66634 6.52162 9.21243 6.91829 8.62685 7.43003C8.30489 7.7114 7.98839 7.98798 7.81114 8.35455C7.65086 8.68602 7.36786 8.99967 6.99967 8.99967C6.63148 8.99967 6.31971 8.69539 6.40659 8.3376C6.58816 7.58991 7.07467 7.21701 7.5133 6.88079C7.9465 6.54875 8.33301 6.25248 8.33301 5.66634C8.33301 4.93301 7.73301 4.33301 6.99967 4.33301C6.49743 4.33301 6.05773 4.61444 5.83049 5.02739C5.65297 5.34996 5.36786 5.66634 4.99967 5.66634C4.63148 5.66634 4.32445 5.36323 4.41521 5.00641Z"
        fill="#A3A7AF"
      />
    </SVGIcon>
  );
};

export const CopyIcon = (props: SVGIconProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
    >
      <path
        d="M15.1667 7.3335V4.76683C15.1667 3.74007 15.1667 3.22668 14.9669 2.83451C14.7911 2.48955 14.5106 2.20909 14.1657 2.03332C13.7735 1.8335 13.2601 1.8335 12.2333 1.8335H5.26668C4.23991 1.8335 3.72653 1.8335 3.33436 2.03332C2.9894 2.20909 2.70893 2.48955 2.53316 2.83451C2.33334 3.22668 2.33334 3.74007 2.33334 4.76683V11.7335C2.33334 12.7603 2.33334 13.2736 2.53316 13.6658C2.70893 14.0108 2.9894 14.2912 3.33436 14.467C3.72653 14.6668 4.23991 14.6668 5.26668 14.6668H7.83334M10.7667 20.1668H17.7333C18.7601 20.1668 19.2735 20.1668 19.6657 19.967C20.0106 19.7912 20.2911 19.5108 20.4669 19.1658C20.6667 18.7736 20.6667 18.2603 20.6667 17.2335V10.2668C20.6667 9.24007 20.6667 8.72668 20.4669 8.33451C20.2911 7.98955 20.0106 7.70909 19.6657 7.53332C19.2735 7.3335 18.7601 7.3335 17.7333 7.3335H10.7667C9.73991 7.3335 9.22653 7.3335 8.83436 7.53332C8.4894 7.70909 8.20893 7.98955 8.03316 8.33451C7.83334 8.72668 7.83334 9.24007 7.83334 10.2668V17.2335C7.83334 18.2603 7.83334 18.7736 8.03316 19.1658C8.20893 19.5108 8.4894 19.7912 8.83436 19.967C9.22653 20.1668 9.73991 20.1668 10.7667 20.1668Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TransferBarcodeIcon = (props: SVGIconProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
    >
      <rect
        x="1.70825"
        y="0.946777"
        width="13"
        height="22"
        rx="2"
        stroke="#323232"
        strokeWidth="1.5"
      />
      <path
        d="M9.20825 14.9468H10.2083C10.4844 14.9468 10.7083 14.7229 10.7083 14.4468V13.4468M7.20825 14.9468H6.20825C5.93211 14.9468 5.70825 14.7229 5.70825 14.4468V13.4468M7.20825 9.94678H6.20825C5.93211 9.94678 5.70825 10.1706 5.70825 10.4468V11.4468M9.20825 9.94678H10.2083C10.4844 9.94678 10.7083 10.1706 10.7083 10.4468V11.4468"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.7083 19.6968C15.1225 19.6968 15.4583 19.361 15.4583 18.9468C15.4583 18.5326 15.1225 18.1968 14.7083 18.1968V19.6968ZM1.70825 19.6968H14.7083V18.1968H1.70825V19.6968Z"
        fill="#323232"
      />
      <path
        d="M14.7083 6.69678C15.1225 6.69678 15.4583 6.36099 15.4583 5.94678C15.4583 5.53256 15.1225 5.19678 14.7083 5.19678V6.69678ZM1.70825 6.69678H14.7083V5.19678H1.70825V6.69678Z"
        fill="#323232"
      />
      <path d="M6.70825 3.44678H9.70825" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M9.10834 20.8468C9.10834 21.3438 8.7054 21.7468 8.20834 21.7468C7.71129 21.7468 7.30834 21.3438 7.30834 20.8468C7.30834 20.3497 7.71129 19.9468 8.20834 19.9468C8.7054 19.9468 9.10834 20.3497 9.10834 20.8468Z"
        fill="#323232"
      />
    </svg>
  );
};
